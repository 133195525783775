.feed-tab-posts-table {
	display: flex;
	flex-direction: column;
	gap: 40px;
	overflow: hidden;
}
.feed-tab-post-row {
	display: flex;
	gap: 30px;
}
.feed-tab-post-row-grab {
	cursor: grab;
}

.feed-tab-row-media {
	width: 35%;
	text-align: center;
	position: relative;
	max-height: 300px;
}
.feed-tab-row-media-wrapper {
	width: 100%;
	height: 100%;
}
.feed-tab-row-actions-mobile {
	display: none;
}
.feed-tab-row-tags {
	width: 40%;
}
.feed-tab-media {
	object-position: 5% 0%;
	/* object-fit: cover; */
	object-fit: contain;
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

.feed-tab-actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 25%;
	gap: 15px;
	align-items: stretch;
	padding: 0 10px;
}
.feed-tab-buttons {
	width: 80%;
	color: black;
	border: solid black 1px;
	border-radius: 5px;
}
.creator-filter-div{
	margin-top: 3%;
}
.search-container-tagInput {
	width: 100%;
	box-sizing: border-box;
	border: none;
	outline: none;
	background-color: #f1f1f1;
	margin: auto;
	height: 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 15px;
	overflow: hidden;
	margin-bottom: 20px;
}
.tagInput {
	border: none;
	outline: none;
	height: 100%;
	color: #000;
	font-size: 15px;
	width: 100%;
	background: #f1f1f1;
	color: #000;
	padding: 0 10px;
}
.content-modal .ant-btn-primary{
	color:#fff;
	background-color: #000;

}
.content-modal .ant-btn-primary:hover{
	color:#fff;
	background-color: #000 !important;

}
.content-modal .ant-btn-default{
	display: none;
}
.content-modal .ant-modal-close{
	display:none
}

@media screen and (max-width: 540px) {
	.feed-tab-post-row {
		gap: 30px;
		flex-direction: column;
		border-bottom: gainsboro 1px solid;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}
	.feed-tab-row-media {
		width: 100%;
		max-height: 300px;
		display: flex;
		align-items: stretch;
		overflow: hidden;
	}
	.feed-tab-media {
		border-radius: 0px;
	}
	.feed-tab-row-media-wrapper {
		width: 50%;
		height: 100%;
	}
	.feed-tab-row-actions-mobile {
		display: block;
		width: 50%;
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-direction: column;
		gap: 15px;
		padding: 0 10px;
	}
	.feed-tab-row-tags {
		width: 100%;
	}
	.feed-tab-actions {
		display: none;
	}
}
.slick-dots li button{
	/* color: red !important; */
	background: black !important;
}
.feed-tab-media-carousel .slick-dots-bottom {
    bottom: -11%;
}
@media screen and (max-width: 600px) {
	.feed-tab-media-carousel .slick-dots-bottom {
		bottom: -16%;
	}
}
.creator-feed-content-date {
	font-size: 0.8rem;
	margin: 0;
  }

.pagination {
	margin-top: 16px;
	text-align: center;
	display: block !important;
}
.circleFill {
	font-size: 16px;
	margin: 0 7px 1px 0;
}
.shopLink {
	word-wrap: break-word;
	font-size: 0.8rem;
}
  