.account-social-content-connect {
	width: 95%;
	display: flex;
	flex-direction: column;
	margin: 30px 0 70px 0;
	border-radius: 8px;
	overflow: hidden;
	background-color: #fcfcfc;
	border: solid #e8e8e8 1px;
}

.account-social-content-connect-row-platform {

	display: flex;
	align-items: center;
	/* justify-content: center; */
	/* padding: 15px 0; */
	/* cursor: pointer; */
	justify-content: space-between;
}

.account-social-content-connect-row-platform .platform-switch {
	/* align-items:end; */
	/* margin-left: 40%; */
	margin-left: 0%;
	display: flex;
	gap: 15px;
	margin-right: 15%;

}

.account-social-content-connect-row {

	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 15px 0; */
	padding: 20px;
	cursor: pointer;
}

.account-social-content-connect-row-cont {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 150px;
	gap: 12px;
}

.account-social-content-connect-row-cont p {
	margin: 0;
}

.account-social-content-connect-status {
	border: balck;
	color: black;
	border: solid black 1px;
	background-color: white;
	border-radius: 5px;
	padding: 3px 8px;
	display: flex;
	gap: 3px;
	font-size: 12px;
	align-items: center;
	justify-content: center;
}

.account-social-content-connect-status.active {
	border: solid rgb(177, 240, 199) 1px;
	background-color: rgb(177, 240, 199);
	color: rgb(54, 146, 82);
	height: 20px;
	width: 20px;
	padding: 0;
	border-radius: 100%;
	cursor: default;
}

.account-social-content-channelid-text {
	color: #0066cc;
	text-decoration: underline;
	margin: 20px 0 0 0;
	font-size: 0.9rem;
	cursor: pointer;
}

.account-social-content-channelid-ins {
	margin: 20px 0 20px 0;
}

.account-social-content-channelid-link {
	color: #0066cc;
	text-decoration: underline;
	margin: 0;
	cursor: pointer;
	background-color: transparent;
}

.account-social-content-channelid-link:hover {
	color: #0066cc;
	text-decoration: underline;
	background-color: transparent;
}



@media screen and (max-width: 600px) {

	.account-social-content-connect {
		width: 95%;
	}

	.account-social-content-connect-row-platform {
		justify-content: flex-start;
		width: 65%;
		/* margin-left: 2%; */
	}

	.account-social-content-connect-row-platform .platform-switch {
		margin-left: 25%;
		margin-right: 0%;
	}

}

.drop-down-button {
	background: black;
	color: white;
	font-size: 0.6rem;
	margin-left: 1%;
	vertical-align: top;
}

.edit-input {
	width: 80%;
	margin-bottom: 3%;
	margin-left: 4%;
}

.remove-button {
	background: rgb(241, 98, 98);
	font-size: 0.6rem;
	color: white;
	float: right;
	margin-right: 1%;
	margin-top: -1%;
}

.link-p {
	margin-left: 4.5%;
	margin-top: 0.8%;
}

.confirmation-dialog-p {
	color: #414141;
	text-align: center;
}

.confirm-btn-color {
	background-color: #000;
	color: #fff;
}

.mr-2 {
	margin-right: 2%;
}

.v-center {
	vertical-align: text-bottom;
}

.ml-2 {
	margin-left: 2%;
}

.mt-5 {
	margin-top: 5%;
}

.mt-4 {
	margin-top: 4%;
}

.mt-2 {
	margin-top: 2%;
}

.font-bold {
	font-weight: bold;
}
.w-100 {
	width: 100%;
}

.date-selector {
	display: flex;
	gap: 10px;
}

.social-button-style {
    background-color: black;
    color: white;
    font-size: 0.8rem;
    margin-left: 1%;
}

.height-space {
    opacity: 0.1;
    max-width: 100%;
    width: 100%;
    margin: 0;
}

.spin-style {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.connect-row-width {
    width: 96%;
    margin: auto;
}

.content-connect-color {
    background-color: #fff;
    border: solid #fff 1px;
}

.error-alert {
	margin: 10px 0;
}

.chevron {
	margin: 0 6px 0 0;
}

.text-right {
	text-align: right;
}

.modal-p {
	margin-top: 2%;
	font-size: 1rem;
}

.font-16 {
	font-size: 16px;
}

.confirm-button {
	background-color: #404040;
	color: #fff;
}

.lock-style {
	display: flex;
	align-items: center;
	gap: 8px;
}

.skeleton-controls {
	border-radius: 10px;
    width: 200px;
    padding: 20px;
}

.mr-3 {
	margin-right: 3%;
}

.ml-15 {
	margin-left: 15%;
}

.tags-skeleton {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.card-skeleton {
	width: 100%;
    height: 300px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px;
}

.hashtag-skeleton {
	padding: 16px;
	margin-top: -33%;
	border-radius: 10px;
}

.filter-hashtag-skeleton {
	height: 8%;
	border: none;
	background-color: hwb(0 89% 9%);
	padding: 16px;
	border-radius: 10px;
	margin-right: 1%;
	color: #000;
}

.skeleton-wrapper-input {
	width: 95%;
    height: 100%;
    border: none;
    outline: none;
    color: #000;
    font-size: 18px;
    border-radius: 50px;
    padding-left: 25px;
    float: left;
    background: #f1f1f1;
    margin-top: 6%;
}

.hw-40 {
	height: 40px;
	width: 40px;
}

.hw-100 {
	width: 100px;
    height: 100px;
}

.mt-25 {
	margin-top: 25px;
}

.confirm-btn-add {
	background-color: black;
	color: #fff;
}

.edit-btn {
	background: black;
    color: white;
    margin-left: 4%;
    font-size: 0.6rem;
    width: auto;
}

.socila-link-button {
	width: max-content;
	margin: 0;
	padding: 1px;
	border: none;
	font-size: 1.5rem;
  }

  .socila-link-button svg {
	vertical-align: unset;
  }
  
  .confirmation-dialog-icon {
	width: 80%;
	margin: auto;
	align-content: center;
	text-align: center;
	margin-top: 3%;
  }
  
  .save-button {
	background-color: black;
	color: #fff;
  }

  .social-connect-row {
	display: flex;
	justify-content: flex-end;
	text-align: center;
	padding: 1% 2%;
  }