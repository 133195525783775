.modal-content-btn{
 margin-left: 5%;
}
.content-wrapper{
    margin:4%
}
.post-id-input{
    margin-bottom: 4%;
}
.caption-input{
    margin-bottom: 4%;
}
.permalink-input{
    margin-bottom: 4%;
}
.post-date-input{
    margin-bottom: 4%;
}
.ant-radio-button-wrapper-checked{
    border: none;
}
.ant-radio-group-solid{
    background: none;
    border: none;
}
.input-value{
   border:1px solid black;
   margin-left: 4%;
   width:70%;
}
.input-title{
    font-size: 1rem;
}
.content-block{
    display: flex;
    flex-direction: row;
}
.primary-color {
    background-color: #414141 !important;
}
.check {
    border-radius: 50%;
    margin-right: 6%;
    margin-left: 5%;
}
.text-center {
    text-align: center;
}