.stat-box-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #f4f4f4;
	border-radius: 10px;
	padding: 10px 20px;
	width: 100%;
}
.stat-box-title {
	font-size: 20px;
	font-weight: 500;
	margin: 0;
}
.stat-box-number {
	font-size: 25px;
	margin: 0;
}

.stat-box-display {
	display: flex;
    gap: 10px;
    align-items: center;
}
