.links-wrapper{
    margin-top: 5%;
}

.account-values-profiles{
    margin-bottom: 4%;
}

.add-link {
    background: black;
    color: white;
    margin-left: 1%;
    font-size: 0.8rem;
}