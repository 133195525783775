.header {
  display: flex;
  flex-direction: row;
}
.nav-title {
  height: 40px;
  color: #000;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  font-weight: bold;
}

.nav-menu {
  background-color: #404040;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 6.8rem;
  right: -100%;
  transition: 850ms;
  z-index: 1;
}
.nav-menu.active {
  right: 0;
  transition: 350ms;
}
.nav-menu-items {
  width: 100%;
}
.nav-text {
  margin-top: 4%;
}
.dashboard-img {
  margin-left: 12%;
  margin-top: 12%;
  background-color: #404040;
  color: #fff;
  font-size: 2rem;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid black;
}
.sidebar-img {
  width: 10%;
  text-align: center;
}
li {
  list-style-type: none;
}
.nav-text h3 {
  color: #fff;
  text-align: center;
}
.dash-head {
  width: 90%;
}
.sidebar {
  width: 11rem;
  margin-top: 2%;
  margin-left: -15%;
  background-color: #404040;
  color: #fff;
  border: none;
  font-size: 1.3rem;
}
.sidebar:hover {
  background-color: #fff;
  color: #404040;
}
