.card-style{
    /* width:100%;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

.card-text{
    margin-bottom: 5px;
    font-size: 20px;
    color: #535151;
}

.card-numbers {
    font-size: 20px;
    margin-bottom: 5px;
    color: black;
    font-size: 25px;
    margin: 0;
}

.h-5 {
    height: 5%;
}