.thumbnail-div {
	width: 100%;
	text-align: center;
	margin-left: -20%;
}

.thumbnail-video {
	background-size: cover;
	width: 33%;
	height: 9rem;
	object-fit: cover;
	object-position: 5% 0%;
}

.thumbnail {
	width: 33%;
	height: 9rem;
	object-fit: cover;
	object-position: 5% 0%;
}
.index {
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 40%;
}
.index-column {
	margin-top: 15%;
}
.index-btn {
	background-color: #404040;
	color: #fff;
	border-radius: 4px;
	border: none;
}
.tabs {
	margin-top: 3%;
	margin-bottom: 3%;
	flex-basis: 0;
	justify-content: center;
}
.tabs button {
	width: 33.33%;
	background-color: #fff;
	height: 35px;
}
.tab-active {
	border: 1px solid gray;
	border-radius: 4px 4px 0 0;
	border-bottom: none;
	color: #000;
	font-weight: 600;
}
.tab {
	border-bottom: 1px solid gray;
	border-top: none;
	border-left: none;
	border-right: none;
	color: #000;
}

hr {
	max-width: 600px;
}

.search-container-tagInput {
	margin-top: 3%;
}

@media (max-width: 575.98px) {
	.thumbnail-video {
		background-size: cover;
		width: 55%;
		margin-left: 10%;
	}
	.thumbnail {
		background-size: cover;
		width: 55%;
		margin-left: 10%;
	}
	.index {
		text-align: center;
		align-items: center;
		justify-content: center;
		width: 30%;
	}
}

@media (max-width: 767.98px) {
	.index-column {
		margin-left: 30%;
		margin-top: 15%;
	}
}

.table-responsive {
	min-height: 0.01%;
	overflow-x: auto;
}

@media screen and (max-width: 767px) {
	.table-responsive {
		margin-bottom: 15px;

		border: 1px solid #ddd;
	}
	.table-responsive > .table {
		margin-bottom: 0;
	}
	.table-responsive > .table input {
		max-width: 60%;
		margin-left: -10%;
	}
	.tag-container {
		margin-left: -10%;
	}
	.tags {
		margin: 0.5px 0.5px;
	}
	.table-responsive > .table > thead > tr > th,
	.table-responsive > .table > tbody > tr > th,
	.table-responsive > .table > tfoot > tr > th,
	.table-responsive > .table > thead > tr > td,
	.table-responsive > .table > tbody > tr > td,
	.table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}
}
@media screen and (max-width: 535px) {
	.thumbnail-div {
		width: 150px;
	}
	.index-btn {
		margin-top: 4%;
		width: fit-content;
	}
	.table-responsive {
		margin-bottom: 15px;

		border: 1px solid #ddd;
	}
	.table-responsive > .table {
		margin-bottom: 0;
	}
	.table-responsive > .table input {
		max-width: 60%;
		margin-left: -14%;
	}
	.tag-container {
		margin-left: -14%;
	}
	.tag {
		margin: 0.5px 0.5px;
		width: fit-content;
		font-size: 0.3px;
	}

	.table-responsive > .table > thead > tr > th,
	.table-responsive > .table > tbody > tr > th,
	.table-responsive > .table > tfoot > tr > th,
	.table-responsive > .table > thead > tr > td,
	.table-responsive > .table > tbody > tr > td,
	.table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}
}
