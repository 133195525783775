.tags-box-see-all {
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
	/* color: #007bff; */
}
.tags-box-see-less {
	display: none;
}
.tags{
	margin-top: 3%;
}
@media screen and (max-width: 540px) {
	.tags-box-see-less {
		text-decoration: underline;
		cursor: pointer;
		font-size: 14px;

		/* color: #007bff; */
		display: block;
	}
}
