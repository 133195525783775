*,
*::before,
*::after {
  box-sizing: inherit;
}
.App {
  height: 100%;
}
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
.login-app {
  display: none;
}
html body {
  padding: 20px;
  margin: 0;
  /* background-color: #282828; */
}
#app-wrapper {
  width: 90%;
  margin: 3% 5%;
}
.row {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.row {
  flex-shrink: 0;

  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.dashboard-mobile-show {
  display: none;
}

.drawer {
  height: 90%;

  width: 100%;

  font-size: 1.3rem;
  align-items: center;
  margin-top: 4.75rem;
}
.drawer-menu {
  background: #f5f2f2;
  color: #000;
  border-radius: 10px;
}

