#slidingVertical input::placeholder {
    color: transparent;
  }
  
  #slidingVertical input::-webkit-input-placeholder {
    color: transparent;
  }
  
  #slidingVertical input::-moz-placeholder {
    color: transparent;
  }
  
  #slidingVertical input::-ms-input-placeholder {
    color: transparent;
  }
  
  #slidingVertical input:-moz-placeholder {
    color: transparent;
  }
  
  #slidingVertical input {
    font-size: 1rem;
  }
  
  #slidingVertical {
    position: relative;
    overflow: hidden;
    width: 300px; /* Adjust width as needed */
  }
  
  #slidingVertical span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    animation: rotatePlaceholders 15s linear infinite;
  }
  
  @keyframes rotatePlaceholders {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  .max-tags-text{
    font-size: 0.7rem;
    margin-top: -0.2%;
    margin-bottom: 1.2%;
    width: fit-content;
    margin-left: 0%;
    /* border:1px solid black */
  }
  .max-tags-text p{
   padding: 0px 5px;
   /* color:#3d3c3c !important; */
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: white;
  }

  .tags-text-color {
    color: #3d3c3c;
  }

  .font-1 {
    font-size: 1rem;
  }

  .input-color {
    color: #f1f1f1;
  }

  .search-button {
    background: #000;
    margin-top: -20%;
    margin-bottom: 7%;
    color: #fff;
    padding: 0.5rem 1rem;
  }

  .edit-button {
    background-color: black;
    color: white;
    font-size: 0.8rem;
    margin-left: 1%;
  }

  .search-hr {
    opacity: 0.1;
    max-width: 100%;
    width: 100%;
    margin: 0%;
  }