.tabs {
  border: 1px solid black;
  margin-top: 2%;
}
.btn-primary {
  margin-left: 3%;
}

.dropdown {
  height: auto;
  margin-left: 2%;
}
#drop {
  margin-left: 1.7%;
}
.option-component {
  margin-left: 2.3%;
  margin-top: 2%;
}

.push-btn {
  background-color: #404040;

  color: #fff;

  border: 2px solid #404040;
  border-radius: 10px;
  cursor: pointer;

  outline: none;
  padding: 12px;

  font-size: 1.25rem;
}

.ant-switch {
  display: block;
  margin-top: 10px;
}

.list {
  margin-left: -12px;
  margin-top: 18px;
}

.table {
  margin-left: -8px;
  margin-top: 10px;
}

.dashboard-mobile-show {
  display: none;
}
.thumbnail-div {
  width: 100%;
}
.thumbnail {
  max-width: 15%;
}
.column a {
  background-color: #fff;
  color: #000;
}
.column a:hover {
  background-color: #fff;
  color: blue;
}
.index-btn {
  background-color: #404040;
  color: #fff;
  border-radius: 4px;
  border: none;
}

.ant-switch-checked {
  background-color: green !important;
}
.ant-switch-inner-unchecked {
  background-color: rgb(241, 98, 98);
}
:where(.css-dev-only-do-not-override-1i536d8).ant-tooltip .ant-tooltip-inner {
  min-width: 32px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
hr {
	margin-left: 12px;
	margin-right: 12px;
	width: 100%;
}
.nav {
	margin-left: 12px;
	margin-right: 12px;
	width: 97%;
}
.css-1uwabd6.Mui-selected {
	border-radius: 10px;
	background-color: #716d6d !important;
	color: white;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected { 
	border-radius: 10px;
	background-color: #716d6d !important;
	color: white;
}
.d-side {
  width: 96%;
  margin-left: 2%;
  margin-top: 5%;
  background-color: #fff;
}
.content-container {
  display: flex;
}
.left-panel {
  display: inline-block;
  width: 25%;
}
.sort-btn {
  background: none;
  border: none;
}
.table {
  width: 100%;
}
.right-panel {
  display: inline-block;
  width: 75%;
}
#listButton {
	padding: 2%;
	font-size: 1.5rem;
	margin-top: 5%;
	margin-bottom: 5%;
	justify-content: center;
	border-radius: 10px;
	text-align: center;
	/* white-space: nowrap; */
}
#listButton:hover {
	background-color: #716d6d;
	color: #fff;
	border-radius: 10px;
}
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .date-scroll::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	}

	.css-h4y409-MuiList-root::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	}
  .content-container {
    display: block;
  }
  .nav {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}

  .right-panel {
    width: 100%;
		padding-top: 10px;
  }
  .column {
    width: 10%;
  }
  .left-panel {
    width: 100%;
  }

  .css-1ontqvh {
		display: grid;
		grid-template-columns: auto auto auto auto auto;
		grid-column-gap: 10px;
		overflow-x: scroll;
		grid-auto-flow: column;
		background-color: white;
	}

	.css-h4y409-MuiList-root { 
		display: grid;
		grid-template-columns: auto auto auto auto auto;
		grid-column-gap: 10px;
		overflow-x: scroll;
		grid-auto-flow: column;
		background-color: white;
	}

  .css-1tpp0wj {
		background-color: #f1f1f1;
		border-radius: 10px;
	}

	.css-1nxmd3h-MuiListItem-root { 
		background-color: #f1f1f1;
		border-radius: 10px;
	}

  #listButton {
    font-size: 1rem;
		padding: 10px;
		margin: 0px;
		border-radius: 10px;
		white-space: nowrap;
  }

  .drawer {
    margin-top: 0rem;
  }

  .drawer-menu {
    background: #f5f2f2;
    color: #000;
    border-radius: 10px;  }

  #listButton:hover {
    background-color: #716d6d;
		color: #fff;
		border-radius: 10px;
  }
  .list {
    margin-bottom: 5%;
  }

  .MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color: rgba(25, 118, 210, 0.08);
  }

  .analytics-titles {
    justify-content: center;
    grid-template-columns: 1fr;
  }
  .dashboard-mobile-hide {
    display: none;
  }
  .dashboard-mobile-show {
    display: block;
    margin-top: 1rem;
  }
  .val {
    padding-top: 1.25rem;
    justify-content: center;
  }
  .controls {
    justify-content: center;
  }
  .push-btn-div {
    text-align: center;
    padding-bottom: 2rem;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

.d-side {
  background-color: #fff;
}

.val {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  padding-top: 1.25rem;
  padding-bottom: 4rem;
}
.titles {
  display: inline-block;

  width: 15%;
}
.value-input {
  width: 350px;
}

.edit-btn-name,
.edit-btn-phn,
.edit-btn-email,
.done-btn-name,
.done-btn-phn,
.done-btn-email {
  border: none;
  margin-left: 10px;

  border-radius: 40px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 1px;
  padding-bottom: 3px;
}

.dashboard-grid-item {
  border-radius: 10px;
  background: #f1f1f1;
  padding: 20px;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.account-grid-item {
  border-radius: 10px;
  background: #f1f1f1;
  padding: 20px;
}

.account-grid-item-key {
  font-size: 1.25rem;
}

.account-grid-item-value {
  font-size: 1rem;
}

.controls {
  padding-top: 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  padding-bottom: 2rem;
}

.analytics-titles {
  padding-top: 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  padding-bottom: 4rem;
}

.mb-2 {
  margin-bottom: 2%;
}

.mb-4 {
  margin-bottom: 4%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-8 {
  margin-top: 8;
}

.mt-5 {
  margin-top: 5%;
}

.mt-6 {
  margin-top: 6%;
}

.mt-7 {
  margin-top: 7.5%;
}

.mt-2 {
  margin-top: -2%;
}

.mt-4 {
  margin-top: 4%;
}

.mt-25 {
  margin-top: 25px;
}

.ml-2 {
  margin-left: 2%;
}

.p-3 {
  padding: 3px;
}

.p-4 {
  padding: 4%;
}

.mr-2{
  margin-right: 2%;
}

.mr-20 {
  margin-right: 20px;
}

.mh-300 {
  max-height: 300px;
}

.w-20 {
  width: 20%;
}

.text-center {
  text-align: center;
}

.text-bottom {
  vertical-align: text-bottom;
}

.font-size-1 {
  font-size: 1rem;
}

.font-bold {
  font-weight: bold;
}

.full-div {
  width: 100%;
  height: 100%;
}

.cursorOnName {
  cursor: not-allowed;
}

.chart-tooltip {
  z-index: -1;
  background: white;
  border: 1px solid #f1f1f1;
}

.date-format {
  margin: 2%;
  padding: 3px;
}

.chart-list {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.analytics-loader {
  margin-top: 2%;
  margin-bottom: 4%;
  border: 1px solid #f1f1f1;
  width: 96%;
  margin-left: 3%;
  height: 100%;
}

.spin-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.errorMsg {
  color: red;
}

.primary-btn {
  background-color: #414141;
}

.drag-drop {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.w-80 {
  width: 80%;
}

.w-20 {
  width: 20%;
}

.word-break {
  word-wrap: break-word;
}

.bookmark-dash-fill {
  font-size: 16px;
  margin: 0 7px 1px 0;
}

.caption-image {
	display: flex;
	flex-wrap: wrap;
    gap: 10px;
    margin-top: 8%;
}

.image-tag {
	display: flex;
	flex-wrap: wrap;
    gap: 10px;
    margin-top: 6%;
	border: 0.5px solid black;
}

.search-input {
  margin: 0 0 40px 0;
}

.selected-creator {
  margin: 0 auto;
  margin-top: 5%;
  border: 1px solid skyblue;
}

.modal-label {
  text-align: left;
  font-weight: bold;
}

.upload {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.upload-img {
  text-align: center;
  align-items: center;
}

.uploa-btn {
 display: block;
 margin: auto;
 text-align: center;
}

.product-row {
  max-height: 300px;
  align-items: center;
}

.product-img {
  width: 35%;
  max-height: 300px;
}

.edit-btn {
  background-color: #404040;
  margin-bottom: 2%;
  color: white;
}

.btn-style {
  display: flex;
  width: 60%;
  margin: 1%;
  gap: 10px;
}

.save-btn {
  background: #000;
    color: #fff;
    margin-top: 10px;
    width: 45%;
}

.cancel-btn {
  color: white;
    background-color: rgb(241, 98, 98);
    font-size: 0.6rem;
    margin: 2%;
    border: none;
    padding: 4px 15px;
    border-radius: 6px;
}

.add-btn {
  color: white;
    background-color: black;
    font-size: 0.6rem;
    margin: 2%;
    border: none;
    padding: 4px 15px;
    border-radius: 6px;
}

.confirmation-btn-style {
  width: 80%;
  margin: auto;
  align-content: center;
  text-align: center;
  margin-top: 3%;
}

.confirmation-p {
  color: #414141;
  text-align: center;
  margin-top: 4%;
}

.ant-btn-disabled :hover{
  color: black;
  background: #414141 !important;
}