.no-links-msg {
  color: #404040;

  padding: 20px;
  margin-bottom: 0px;
}

.account-social-content-connect {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 0 50px 0;
  background-color: #fcfcfc;
  border: solid #e8e8e8 1px;
  width: 100% !important;
}

.account-social-link-connect-row {
  display: flex;

  padding: 15px 0;
  cursor: pointer;
}

.account-social-link-connect-row-cont {
  gap: 12px;
  width: 100%;
}

.account-social-link-connect-row-cont p {
  color: black;
  /* margin: 0;
  margin-left: 4%; */
  margin-bottom: 0px;
  overflow-wrap: break-word;
  /* margin-right: 1%; */
}

.confirmation-dialog {
  justify-content: space-between;
  margin-top: 10px;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.confirmation-dialog button {
  width: 30%;
  margin-left: 12%;

  background-color: #fff;
  color: black;
  border: 1px solid black;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

