.dashboard-grid-items-grid { 
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	padding-bottom: 2rem;
}

.controls {
	padding-top: 1.25rem;
	display: grid;
	/* grid-template-columns: repeat(auto-fill, 350px); */
	grid-template-columns: 1fr;
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	padding-bottom: 2rem;
}

.min-range {
	color: grey;
	margin-top: 2.8%;
	font-size: 14px;
	padding-right: 5px;
}
.max-range {
	color: gray;
	margin-top: 2.7%;
	font-size: 14px;
	float: right;
	margin-left: 3%;
}

.push-btn {
	background-color: black;

	color: #fff;
	margin-right: 3%;
	border: 2px solid black;
	border-radius: 10px;
	cursor: pointer;

	outline: none;
	/* padding: 12px; */

	font-size: 1.25rem;
	width: 200px;
	margin-bottom: 25px;

	padding: 8px;
}
.preview-btn {
	background-color: black;

	color: #fff;
	margin-left: 3%;
	border: 2px solid black;
	border-radius: 10px;
	cursor: pointer;

	outline: none;
	padding: 12px;

	font-size: 1.25rem;
	width: 200px;
}
.push-btn:disabled {
	background-color: lightgrey;

	color: #0c0a0a;
	text-align: center;

	border: 1px solid #080606;
	border-radius: 10px;
	cursor: not-allowed;

	outline: none;
	padding: 12px;
	margin-bottom: 25px;
	font-size: 1.25rem;
	width: 200px;
}
.spin-btn {
	background-color: lightgrey;

	color: #0c0a0a;
	text-align: center;
	margin-right: 3%;

	width: 200px;
	border: 1px solid #080606;
	border-radius: 10px;
	cursor: not-allowed;

	outline: none;
	padding: 12px;
	margin-bottom: 25px;
	font-size: 1.25rem;
}
@media (max-width: 767.98px) {
	.controls {
		justify-content: center;
		padding-top: 0px;
	}
	.push-btn-div {
		text-align: center;
		padding-bottom: 2rem;
	}
}

.controls-tab-row-queries{
	margin-top: 2%;
	width:80%;
	margin-left: 4%;

}
.custom-category-queryInput{
	width: 100%;
	box-sizing: border-box;
	border:none;
	outline: none;
	background-color: #fff !important;
	margin: auto;
	height: 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 15px;
	overflow: hidden;
	margin-bottom: 10px;
}
.custom-category-functions{
	float: right;
	margin-right: 2%;
	justify-content: space-between;
}
.section-heading{
font-size: 20px;
 margin: 25px 0 15px 0;
 margin-top: -1.5%;
}
@media only screen and (max-width: 650px) {
	.section-heading{
		font-size: 20px;
		 margin: 20px 0 15px 0;
		 /* margin-left: 5%; */
		}

		.dashboard-grid-items-grid { 
			grid-template-columns: 1fr;
			padding-bottom: 0px;
		}

		/* .controls { 
			grid-template-columns: 1fr;
		} */

		[data-rbd-droppable-context-id="0"] {
			grid-template-columns: 1fr !important;
		}
}

.category-grid{
	display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 25px;

                    padding-top: 1.25rem;
                    padding-bottom: 2rem;
}

.ant-tabs-tab { 
	background: transparent !important; 
	border: none !important;
	/* margin-bottom: 8px !important; */
	/* margin: 8px 16px !important;
	padding: 0px !important; */
}

/* .ant-tabs-tab:hover { 
	background-color: rgb(217, 217, 217) !important;
	border-radius: 5px !important;
	padding: 5px 16px !important;
} */

.ant-tabs-ink-bar { 
	height: 5px !important;
}

.ant-tabs-tab.ant-tabs-tab-active { 
	border: none !important;
    border-bottom: 3px solid black !important;
}

.dashboard-mobile-hide { 
	padding-bottom: 12px;
}

@media (max-width: 768px) {
    .category-grid {
        display: block; /* Change to block or any other desired display property for mobile */
        grid-template-columns: auto; 
grid-column-gap:40px;
        grid-gap: 20px; /* Adjust grid gap for mobile if needed */
        padding: 1rem; /* Adjust padding for mobile if needed */
    }
	.category-grid > * {
        margin-bottom: 2rem; /* Adjust the margin-bottom value for the desired vertical spacing */
    }
}

.content-type-radios{
	margin-top: 1%;
}
/* Your custom styles to override the specific CSS */
.ant-radio-button-wrapper:not(:first-child)::before {
    position: static; /* Or any other appropriate value to override the 'absolute' position */
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 0;
    height: 0;
    padding-block: 0;
    padding-inline: 0;
    background-color: transparent; /* Or the desired background color */
    transition: none; /* Remove the transition if not needed */
    content: none; /* Remove the content if not needed */
}

.info-tooltip{
	height:12px
}

.default-category {
	font-size: 15px;
	margin-bottom: 10px;
}

.outlineDragIndicator {
	margin-left: -2%;
	margin-right: 1.5%;
}

.default-category-input {
	width: 88%;
	margin-bottom: 8%;
}

.subcategory {
	font-size: 1rem;
	margin-bottom: 8%;
}

.input-tag {
	background-color: white;
	width: 80%;
}

.tag-box {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.create-btn {
	margin-left: 1%;
	background-color: black;
	color: #fff;
	border-color: black;
}

.radio-btn {
	border: none;
	outline: none;
}