.dashboard-grid-item {
	border-radius: 10px;
	background: #f1f1f1;
	padding: 20px;
	font-size: 1.25rem;
}
.dashboard-grid-item p {
	margin: 0;
}
.error-msg {
	color: red;
}
.categories {
	padding-top: 1.25rem;
	padding-bottom: 2rem;
}

.tag-input {
	background-color: white;
	width: 80%;
}

.font-1 {
	font-size: 1rem;
}

.queries {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}