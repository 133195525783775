.analytics-table {
	max-width: 90%;
	margin-top: 10px;
	font-size: 1rem;
	margin-left: 2%;
}

.analytics-list {
	font-size: 1rem;
	line-height: normal;
	margin-bottom: 5%;
	margin-left: -12px;
	margin-top: 18px;
}
.analytics-postClicks-thumbnail-div {
	width: 100%;
}
.analytics-postClicks-thumbnail {
	max-width: 25%;
    vertical-align: unset;
}


@media only screen and (max-width: 650px) {
	.tool-tip{
		font-size: 0.6rem;
		word-wrap: break-word;
	}
}

.text-center {
	text-align: center;
}

.mb-20 {
	margin-bottom: 20px;
}

.mr-20 {
	margin-right: 20px;
}

.search-form {
	font-size: 20px;
	margin: 25px 0 15px 0;
}

.tab-key {
	display: flex;
	align-items: center;
}

.tab-lock {
	margin-right: 4px;
	color: grey;
}

.text-right {
	text-align: right;
}

.upgrade-text {
	margin-top: 2%;
	font-size: 1rem;
}

.pagination {
	margin-top: 16px;
	text-align: center;
	display: block !important;
}

.tooltip {
	z-index: -1;
	background: white;
	border: 1px solid #f1f1f1;
}

.date-format {
	margin: 2%;
	padding: 3px;
}

.user-chart {
color: #8685EF;
padding: 3px;
}

.search-chart {
	color: #404040;
	padding: 3px;
}

.clicks-chart {
	color: #00C6CF;
	padding: 3px;
}

.custom-legend {
	display: flex;
	justify-content: center;
	margin-top: 2%;
}

.full-div {
	width: 100%;
	height: 100%;
}

.page-loader {
	margin-top: 2%;
	margin-bottom: 4%;
	/* border: 1px solid #f1f1f1; */
	width: 96%;
	margin-left: 3%;
	height: 100%;
}