.tags-box-see-all {
	text-decoration: underline;
	cursor: pointer;
	color: #007bff;
}
.tags-box-see-less {
	display: none;
}
.tags{
	margin-top: 3%;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.all-tags {
	background-color: #3b3b3b;
	color: white;
	display: flex;
	border-radius: 5px;
	padding: 2px 5px;
	align-items: center;
}

.all-tags p {
	margin: 0;
	font-size: 14px;
}

.close-tag-icon {
	margin-left: 10px;
	margin-right: 4px;
	cursor: pointer;
	color: white;
	font-size: 18px;
}

@media screen and (max-width: 540px) {
	.tags-box-see-less {
		text-decoration: underline;
		cursor: pointer;
		color: #007bff;
		display: block;
	}
}
